import logo from "./logo.svg";
import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Login } from "./auth/login";
import Dashboard from "./pages/dashboard";
import { Home } from "./pages/home";
import { Announcements, Product } from "./pages/products";
import { News, Orders } from "./pages/news";
import "bootstrap/dist/css/bootstrap.css";
import { useEffect } from "react";
import { Appeals } from "./pages/appeals";
import { Publications } from "./pages/publications";
import { Seedlings } from "./pages/seedlings";
import { OrderOfSeedlings } from "./pages/orderOfSeedlings";
import { OrderOfSeedlingsDetails } from "./pages/orderOfSeedlingsDetails";
import { SeedlingsTypes } from "./pages/seedlingsTypes";
import { NewSpecies } from "./pages/newSpecies";
import { Services } from "./pages/services";
import { OrderForServices } from "./pages/orderForServices";
import "bootstrap/dist/css/bootstrap.css"
import { Order } from "./pages/order";
import { WareHouse } from "./pages/wareHouse";
import { Register } from "./auth/register";
import { Orderitem } from "./pages/order/orderitem";

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("ACCESS_TOKEN")) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, []);
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/" element={<Dashboard />}>
        {/* <Route index path="/" element={<Home />} /> */}
        {/* <Route index path="/" element={<Appeals />} /> */}
        <Route path="/" element={<Product />} />
        <Route path="/orders" element={<Order />} />
        <Route path="/orders/:id" element={<Orderitem />} />
        <Route path="/warehouse" element={<WareHouse />} />
        <Route path="/seedlings" element={<Seedlings />} />
        <Route path="/orderOfSeedlings" element={<OrderOfSeedlings />} />
        <Route
          path="/orderOfSeedlingsDetails"
          element={<OrderOfSeedlingsDetails />}
        />
        <Route path="/seedlingsTypes" element={<SeedlingsTypes />} />
        <Route path="/newSpecies" element={<NewSpecies />} />
        <Route path="/services" element={<Services />} />
        <Route path="/orderForServices" element={<OrderForServices />} />
      </Route>
    </Routes>
  );
}

export default App;
