import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { regiondGet, districtGet, wareHouseCreate, wareHouseEdit } from "../../api/api";

const { Option } = Select;

export const WareHouseEditAddModal = ({ category, optionModal, setOptionModal }) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState([]);
  const [district, setDistrict] = useState([]);
  const [districtId, setDistrictId] = useState(optionModal?.item?.address?.districtId ?? null);
  const [regionId, setRegionId] = useState(optionModal?.item?.address?.regionId ?? null);

  // Fetch regions on component mount
  useEffect(() => {
    regiondGet()
      .then((res) => {
        setRegion(res.data?.data || []);
      })
      .catch((err) => console.error(err));
  }, []);

  // Fetch districts whenever regionId changes
  useEffect(() => {
    if (regionId) {
      districtGet(regionId)
        .then((res) => {
          setDistrict(res.data?.data || []);
        })
        .catch((err) => console.error(err));
    }
  }, [regionId]);

  // Populate the form when editing
  useEffect(() => {
    if (optionModal?.type === "edit" && optionModal?.item) {
      const { name, streetName, homeNumber, flat, regionId, districtId } = optionModal.item?.address;
      // setRegionId(regionId)
      // setDistrictId(districtId)
      // Set form fields
      form.setFieldsValue({
        name: optionModal?.item?.name,
        address: {
          streetName,
          homeNumber,
          flat,
          regionId,
          districtId,
        },
      });

      // Set regionId to fetch related districts
      setRegionId(regionId);
    } else {
      form.resetFields();
      setRegionId(null);
      setDistrict([]);
    }
  }, [optionModal]);

  const onFinish = (values) => {
    const data = {
      name: values.name,
      address: {
        ...values.address,
      },
    };

    setLoading(true);
    try {
      if (optionModal?.type === "edit") {
        wareHouseEdit(optionModal?.item?.id, data)
          .then(() => {
            message.success("Muvaffaqiyatli o'zgartirildi!");
            form.resetFields();
            setOptionModal({ open: false });
          })
          .catch((err) => message?.error(err?.response?.data?.message))
          .finally(() => setLoading(false));
      } else {
        wareHouseCreate(data)
          .then(() => {
            message.success("Muvaffaqiyatli saqlandi!");
            form.resetFields();
            setOptionModal({ open: false });
          })
          .catch((err) => message?.error(err?.response?.data?.message))
          .finally(() => setLoading(false));
      }
    } catch (err) {
      message?.error(err?.response?.data?.message)
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
      form={form}
    >
      <div className="row">
        <div className="col-12">
          <Form.Item
            label="Nomi"
            name="name"
            rules={[
              {
                required: true,
                message: "Iltimos, nomini kiriting!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Ko'cha nomi"
            name={["address", "streetName"]}
            rules={[
              {
                required: true,
                message: "Iltimos, ko'cha nomini kiriting!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Uy raqami"
            name={["address", "homeNumber"]}
            rules={[
              {
                required: true,
                message: "Iltimos, uy raqamini kiriting!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Kvartira"
            name={["address", "flat"]}
          // rules={[
          //   {
          //     required: true,
          //     message: "Iltimos, kvartira raqamini kiriting!",
          //   },
          // ]}
          >
            <Input type="number" />
          </Form.Item>
        </div>
        <div className="col-6">
          <Form.Item
            label="Hudud"
            name={["address", "regionId"]}
            rules={[
              {
                required: true,
                message: "Iltimos, hududni tanlang!",
              },
            ]}
          >
            <Select
              onChange={(value) => setRegionId(value)} // Update regionId and trigger district fetch
              defaultValue={regionId} // Ensure selected value matches the ID
            >
              <Option value="">Tanlang</Option>
              {region?.map((i) => (
                <Option key={i.id} value={i.id}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="col-6">
          <Form.Item
            label="Tuman"
            name={["address", "districtId"]}
            rules={[
              {
                required: true,
                message: "Iltimos, tumanni tanlang!",
              },
            ]}
          >
            <Select defaultValue={districtId}>
              <Option value="">Tanlang</Option>
              {district?.map((i) => (
                <Option key={i.id} value={i.id}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>
      <Form.Item className="d-flex justify-content-end">
        <Button
          onClick={() => setOptionModal({ open: false })}
          type="default"
          htmlType="reset"
          className="me-2"
        >
          Yopish
        </Button>
        <Button loading={loading} type="primary" htmlType="submit">
          {optionModal?.type === "edit" ? "Yangilash" : "Saqlash"}
        </Button>
      </Form.Item>
    </Form>
  );
};
