import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Upload, message } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import {
  announcmentsCreate,
  announcmentsEdit,
  newsCreate,
  newsEdit,
  wareHouseldGet,
} from "../../api/api";
import { IMAGE_URL } from "../../axios/constants";
import { Option } from "antd/es/mentions";

export const OrderEditAddModal = ({ category, optionModal, setOptionModal }) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [wareHouseld, setwareHouseld] = useState([]);

  const [fileList, setFileList] = useState([]); // State for uploaded images

  useEffect(() => {
    // setLoading(true);
    try {
      wareHouseldGet()
        .then((res) => {
          setwareHouseld(res.data?.data);
        })
        .catch((err) => console.log(err));
    } catch (er) {
      console.log(er);
    }
  }, []);

  useEffect(() => {
    if (optionModal?.type === "edit" && optionModal?.item) {
      // Populate the form and the fileList when editing
      form.setFieldsValue(optionModal.item);
      const initialFileList = optionModal.item.photoPaths.map((path, index) => ({
        uid: `-${index}`, // Unique ID for each file
        name: path.split("/").pop(), // Extract the file name from the path
        url: IMAGE_URL + path, // Full URL of the image
        status: "done", // Mark the file as already uploaded
      }));
      setFileList(initialFileList);
    } else {
      form.resetFields();
      setFileList([]);
    }
  }, [optionModal]);

  useEffect(() => {
    if (optionModal?.type === "edit") {
      form.setFieldsValue(optionModal?.item);
    } else {
      form.resetFields();
    }
  }, [optionModal]);

  const onFinish = async (values) => {
    let data = new FormData();

    // Boshqa form maydonlarini qo'shish
    for (const key in values) {
      if (key !== "file") {
        data.append(key, values[key]);
      }
    }

    // Rasmlarni array ko'rinishida binary formatga yig'ish
    const photosArray = await Promise.all(
      fileList.map(async (file) => {
        if (file.originFileObj) {
          // Yangi yuklangan faylni binary qaytarish
          return file.originFileObj;
        } else {
          // Mavjud rasmni blob formatiga aylantirish
          const response = await fetch(file.url);
          const blob = await response.blob();
          return blob;
        }
      })
    );

    // Rasmlarni `photos` kalitiga massiv ko'rinishida qo'shish
    photosArray.forEach((photo, index) => {
      data.append(`photos[${index}]`, photo);
    });

    // Serverga yuborish
    setLoading(true);
    try {
      if (optionModal?.type === "edit") {
        announcmentsEdit(optionModal?.item?.id, data)
          .then((res) => {
            message.success("Muvaffaqiyatli o'zgartirildi!");
            form.resetFields();
            setOptionModal({ open: false });
            setLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      } else {
        announcmentsCreate(data)
          .then((res) => {
            message.success("Muvaffaqiyatli saqlandi!");
            form.resetFields();
            setOptionModal({ open: false });
            setLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };



  const onFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };


  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      form={form}
    >
      <div className="row">
        <div className="col-4">
          <Form.Item
            label="districtName"
            name="districtName"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="regionName"
            name="regionName"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="streetName"
            name="streetName"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="sumPrice"
            name="sumPrice"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="sumOldPrice"
            name="sumOldPrice"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="status"
            name="status"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Select>
              <Option key={0} value="">Tanlang</Option>
              {wareHouseld?.map(i => (
                <Option key={i?.id} value={i?.id}>{i?.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Kategorya"
            name="categoryId"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Select>
              {/* <Option key="0" value="">Tanlang</Option> */}
              {category?.map(i => (
                <Option key={i?.id} value={i?.id}>{i?.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Tasnif"
            name="description"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </div>
        <div className="col-4">
          <Form.Item
            label="Qisqa tasnif"
            name="shortDescription"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </div>
        <div className="col-8">
          <Form.Item label="Rasm yuklash" name={"file"}>
            <Upload
              customRequest={({ onSuccess }) => {
                onSuccess("ok");
              }}
              fileList={fileList}
              onChange={onFileChange}

              accept="image/*"
              multiple // Allows multiple file uploads
              listType="picture-card"
            >
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  Rasm yuklash
                </div>
              </div>
            </Upload>
          </Form.Item>

        </div>
      </div>

      {/* <Form.Item
        label="Turi"
        name="type"
        rules={[
          {
            required: true,
            message: "Please input your name!",
          },
        ]}
      >
        <TextArea rows={2} />
      </Form.Item> */}

      <Form.Item className="d-flex justify-content-end">
        <Button
          onClick={() => setOptionModal({ open: false })}
          type="default"
          htmlType="reset"
          className="me-2"
        >
          Yopish
        </Button>
        <Button loading={loading} type="primary" htmlType="submit">
          {optionModal?.type === "edit" ? "Yangilash" : "Saqlash"}
        </Button>
      </Form.Item>
    </Form>
  );
};
