import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Modal,
  Pagination,
  Popconfirm,
  Select,
  Tabs,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { NewsEditAddModal } from "../../components/newsEditAddModal";
import Search from "antd/es/input/Search";
import pdf from "../../assets/files/file.pdf";
import { PublicationEditAddModal } from "../../components/publicationEditAddModal";
import { publicationsDelete, publicationsGet } from "../../api/api";
import dayjs from "dayjs";
import { IMAGE_URL } from "../../axios/constants";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

export const Publications = () => {
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState([]);
  const [date, setDate] = useState("");
  const [type, setType] = useState("1");
  const [searchWord, setSearchWord] = useState("");
  const [subType, setsubType] = useState("");
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    page: 1,
    size: 10,
    totalCount: 0,
    totalPage: 0,
  });
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };
  // edit and add modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [optionModal, setOptionModal] = useState({
    open: false,
    type: "",
    item: {},
  });

  useEffect(() => {
    try {
      publicationsGet(10, current, type, searchWord, date, subType)
        .then((res) => {
          setData(res.data?.data?.items);
          setPagination(res?.data?.data?.meta);
        })
        .catch((err) => console.log(err));
    } catch (er) {
      console.log(er);
    }
  }, [current, update, optionModal, type, date, searchWord, subType]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setOptionModal({
      open: false,
    });
  };

  // delete
  const confirm = (id) => {
    try {
      publicationsDelete(id)
        .then((res) => {
          message.success("Muvaffaqiyatli o'chirildi");
          setUpdate((prev) => !prev);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const cancel = (e) => {
    console.log(e);
    // message.error('Click on No');
  };

  // filter date
  const onChangeDate = (date, dateString) => {

    setDate(dateString);
  };

  // on search
  const onSearch = (value) => setSearchWord(value);

  const { size, page, totalCount } = pagination;

  const onChangeTab = (key) => {
    console.log(key);
    setType(key);
  };
  const handleChange = (key) => {
    console.log("key", key);
    setsubType(key);
  };
  const items = [
    {
      key: "1",
      label: `Hammasi`,
      children: (
        <>
          <div className="row">
            <div className="col">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ width: "7%" }} scope="col">
                      Author uz
                    </th>
                    <th style={{ width: "7%" }} scope="col">
                      Author ru
                    </th>
                    <th style={{ width: "7%" }} scope="col">
                      Author en
                    </th>
                    <th scope="col">Mavzu uz</th>
                    <th scope="col">Mavzu ru</th>
                    <th scope="col">Mavzu en</th>
                    <th scope="col">Tasnif uz</th>
                    <th scope="col">Tasnif ru</th>
                    <th scope="col">Tasnif en</th>
                    <th style={{ width: "5%" }} scope="col">
                      Fayl
                    </th>
                    <th style={{ width: "3%" }} scope="col">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <tr key={item.id}>
                      <td>{item.authorUz}</td>
                      <td>{item.authorRu}</td>
                      <td>{item.authorEn}</td>
                      <td>{item.titleUz}</td>
                      <td>{item.titleRu}</td>
                      <td>{item.titleEn}</td>
                      <td>{item.descriptionUz}</td>
                      <td>{item.descriptionRu}</td>
                      <td>{item.descriptionEn}</td>
                      <td>
                        <Button
                          className="d-flex align-items-center"
                          target="_blank"
                          href={IMAGE_URL + item.filePath}
                          type="primary"
                        >
                          <FileAddOutlined /> File{" "}
                        </Button>
                      </td>
                      <td>
                        <div className="d-flex flex-column">
                          <EditOutlined
                            onClick={() => {
                              setIsModalOpen(true);
                              setOptionModal({
                                open: true,
                                type: "edit",
                                item: item,
                              });
                            }}
                            className="fs-4 text-success"
                            style={{ cursor: "pointer" }}
                          />
                          <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this task?"
                            onConfirm={() => confirm(item?.id)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                          >
                            <DeleteOutlined
                              className="fs-4 text-danger my-3"
                              style={{ cursor: "pointer" }}
                            />
                          </Popconfirm>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-center">
              <Pagination
                current={current}
                onChange={onChange}
                total={totalCount}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      key: "ARTICLES",
      label: `Maqolalar`,
      children: (
        <>
          <div className="row">
            <div className="col">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ width: "7%" }} scope="col">
                      Author uz
                    </th>
                    <th style={{ width: "7%" }} scope="col">
                      Author ru
                    </th>
                    <th style={{ width: "7%" }} scope="col">
                      Author en
                    </th>
                    <th scope="col">Mavzu uz</th>
                    <th scope="col">Mavzu ru</th>
                    <th scope="col">Mavzu en</th>
                    <th scope="col">Tasnif uz</th>
                    <th scope="col">Tasnif ru</th>
                    <th scope="col">Tasnif en</th>
                    <th style={{ width: "5%" }} scope="col">
                      Fayl
                    </th>
                    <th style={{ width: "3%" }} scope="col">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <tr key={item.id}>
                      <td>{item.authorUz}</td>
                      <td>{item.authorRu}</td>
                      <td>{item.authorEn}</td>
                      <td>{item.titleUz}</td>
                      <td>{item.titleRu}</td>
                      <td>{item.titleEn}</td>
                      <td>{item.descriptionUz}</td>
                      <td>{item.descriptionRu}</td>
                      <td>{item.descriptionEn}</td>
                      <td>
                        <Button
                          className="d-flex align-items-center"
                          target="_blank"
                          href={item.file}
                          type="primary"
                        >
                          <FileAddOutlined /> File{" "}
                        </Button>
                      </td>
                      <td>
                        <div className="d-flex flex-column">
                          <EditOutlined
                            onClick={() => {
                              setIsModalOpen(true);
                              setOptionModal({
                                open: true,
                                type: "edit",
                                item: item,
                              });
                            }}
                            className="fs-4 text-success"
                            style={{ cursor: "pointer" }}
                          />
                          <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this task?"
                            onConfirm={() => confirm(item?.id)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                          >
                            <DeleteOutlined
                              className="fs-4 text-danger my-3"
                              style={{ cursor: "pointer" }}
                            />
                          </Popconfirm>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-center">
              <Pagination
                current={current}
                onChange={onChange}
                total={totalCount}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      key: "MONOGRAPHS",
      label: `Monogrammalar`,
      children: (
        <>
          <div className="row">
            <div className="col">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ width: "7%" }} scope="col">
                      Author uz
                    </th>
                    <th style={{ width: "7%" }} scope="col">
                      Author ru
                    </th>
                    <th style={{ width: "7%" }} scope="col">
                      Author en
                    </th>
                    <th scope="col">Mavzu uz</th>
                    <th scope="col">Mavzu ru</th>
                    <th scope="col">Mavzu en</th>
                    <th scope="col">Tasnif uz</th>
                    <th scope="col">Tasnif ru</th>
                    <th scope="col">Tasnif en</th>
                    <th style={{ width: "5%" }} scope="col">
                      Fayl
                    </th>
                    <th style={{ width: "3%" }} scope="col">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <tr key={item.id}>
                      <td>{item.authorUz}</td>
                      <td>{item.authorRu}</td>
                      <td>{item.authorEn}</td>
                      <td>{item.titleUz}</td>
                      <td>{item.titleRu}</td>
                      <td>{item.titleEn}</td>
                      <td>{item.descriptionUz}</td>
                      <td>{item.descriptionRu}</td>
                      <td>{item.descriptionEn}</td>
                      <td>
                        <Button
                          className="d-flex align-items-center"
                          target="_blank"
                          href={item.file}
                          type="primary"
                        >
                          <FileAddOutlined /> File{" "}
                        </Button>
                      </td>
                      <td>
                        <div className="d-flex flex-column">
                          <EditOutlined
                            onClick={() => {
                              setIsModalOpen(true);
                              setOptionModal({
                                open: true,
                                type: "edit",
                                item: item,
                              });
                            }}
                            className="fs-4 text-success"
                            style={{ cursor: "pointer" }}
                          />
                          <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this task?"
                            onConfirm={() => confirm(item?.id)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                          >
                            <DeleteOutlined
                              className="fs-4 text-danger my-3"
                              style={{ cursor: "pointer" }}
                            />
                          </Popconfirm>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-center">
              <Pagination
                current={current}
                onChange={onChange}
                total={totalCount}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      key: "ABSTRACT",
      label: `Avtoreferatlar`,
      children: (
        <>
          <div className="row">
            <div className="col">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ width: "7%" }} scope="col">
                      Author uz
                    </th>
                    <th style={{ width: "7%" }} scope="col">
                      Author ru
                    </th>
                    <th style={{ width: "7%" }} scope="col">
                      Author en
                    </th>
                    <th scope="col">Mavzu uz</th>
                    <th scope="col">Mavzu ru</th>
                    <th scope="col">Mavzu en</th>
                    <th scope="col">Tasnif uz</th>
                    <th scope="col">Tasnif ru</th>
                    <th scope="col">Tasnif en</th>
                    <th style={{ width: "5%" }} scope="col">
                      Fayl
                    </th>
                    <th style={{ width: "3%" }} scope="col">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <tr key={item.id}>
                      <td>{item.authorUz}</td>
                      <td>{item.authorRu}</td>
                      <td>{item.authorEn}</td>
                      <td>{item.titleUz}</td>
                      <td>{item.titleRu}</td>
                      <td>{item.titleEn}</td>
                      <td>{item.descriptionUz}</td>
                      <td>{item.descriptionRu}</td>
                      <td>{item.descriptionEn}</td>
                      <td>
                        <Button
                          className="d-flex align-items-center"
                          target="_blank"
                          href={item.file}
                          type="primary"
                        >
                          <FileAddOutlined /> File{" "}
                        </Button>
                      </td>
                      <td>
                        <div className="d-flex flex-column">
                          <EditOutlined
                            onClick={() => {
                              setIsModalOpen(true);
                              setOptionModal({
                                open: true,
                                type: "edit",
                                item: item,
                              });
                            }}
                            className="fs-4 text-success"
                            style={{ cursor: "pointer" }}
                          />
                          <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this task?"
                            onConfirm={() => confirm(item?.id)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                          >
                            <DeleteOutlined
                              className="fs-4 text-danger my-3"
                              style={{ cursor: "pointer" }}
                            />
                          </Popconfirm>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-center">
              <Pagination
                current={current}
                onChange={onChange}
                total={totalCount}
              />
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col d-flex justify-content-between">
          <h3 className="mb-3">Nashrlar</h3>
          <div className="d-flex align-items-center justify-content-center">
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              style={{
                width: 200,
              }}
              allowClear
            />
            <RangePicker
              format={dateFormat}
              onChange={onChangeDate}
              className="mx-2"
            />
            <Select
              // defaultValue="olma"
              style={{
                width: "200px",
              }}
              className="me-2"
              onChange={handleChange}
              allowClear
              options={[
                {
                  label: "Maqolalar",
                  options: [
                    {
                      value: "local",
                      label: "Mahalliy",
                    },
                    {
                      value: "foregin",
                      label: "Xorijiy",
                    },
                  ],
                },
                {
                  label: "Avtoreferatlar",
                  options: [
                    {
                      value: "phd",
                      label: "Phd",
                    },
                    {
                      value: "dsc",
                      label: "Dsc",
                    },
                  ],
                },
              ]}
            />
            <Button
              type="primary"
              onClick={() => {
                setOptionModal({
                  open: true,
                  type: "add",
                  item: {},
                });
              }}
            >
              + Qo'shish
            </Button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Tabs defaultActiveKey="1" items={items} onChange={onChangeTab} />
        </div>
      </div>

      <Modal
        title="Nashrlar"
        width={1000}
        open={optionModal.open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <PublicationEditAddModal
          optionModal={optionModal}
          setOptionModal={setOptionModal}
        />
      </Modal>
    </div>
  );
};
