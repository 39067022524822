import { Button, Form, Input, InputNumber, message, Upload } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { register } from "../api/api";
import { PlusOutlined } from "@ant-design/icons";
import './Register.css';

export const Register = () => {
  const navigate = useNavigate();
  const [photo, setPhoto] = useState(null); // Profil rasmi
  const [enterpriseLogo, setEnterpriseLogo] = useState(null); // Logotip fayli

  // Faylni yuklashni boshqarish
  const handlePhotoUpload = (info) => {
    if (info?.file) {
      setPhoto(info?.fileList?.[0]?.originFileObj); // Profil rasmini saqlash
    } else {
      console.error("Profil rasmi noto'g'ri yuklandi.");
    }
  };

  const handleEnterpriseLogoUpload = (info) => {
    if (info?.file) {
      setEnterpriseLogo(info?.fileList?.[0]?.originFileObj); // Logotip faylini saqlash
    } else {
      console.error("Logotip fayli noto'g'ri yuklandi.");
    }
  };

  const onFinish = async (values) => {
    try {

      const formData = new FormData();
      formData.append("fullName", values.fullName);
      formData.append("phoneNumber", values.phoneNumber);
      formData.append("username", values.username);
      formData.append("password", values.password);
      formData.append("enterpriseName", values.enterpriseName);

      if (photo) {
        formData.append("photo", photo); // Profil rasmi faylini qo'shish
      } else {
        message.error("Iltimos, profil rasmini yuklang!");
        return;
      }

      if (enterpriseLogo) {
        formData.append("enterpriseLogo", enterpriseLogo); // Logotip faylini qo'shish
      } else {
        message.error("Iltimos, kompaniya logotipini yuklang!");
        return;
      }

      await register(formData); // API orqali ma'lumot yuborish
      message.success("Ro'yxatdan o'tish muvaffaqiyatli bajarildi!");
      navigate("/login");
    } catch (error) {
      console.error(error);
      message?.error(error?.response?.data?.message)
      // message.error("Ro'yxatdan o'tishda xatolik yuz berdi.");
    }
  };

  return (
    <div className="register-container">
      <div className="register-form-wrapper">
        <h2 className="form-title">Ro'yxatdan o'tish</h2>
        <Form
          name="register-form"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          className="register-form"
        >
          <Form.Item
            label="To'liq Ism"
            name="fullName"
            rules={[{ required: true, message: "Iltimos, to'liq ismingizni kiriting!" }]}
          >
            <Input placeholder="To'liq ismingizni kiriting" />
          </Form.Item>

          <Form.Item
            label="Telefon Raqami"
            name="phoneNumber"
            rules={[{ required: true, message: "Iltimos, telefon raqamingizni kiriting!" }]}
          >
            <input type="number" min={0} className="custom-input" style={{ width: "100%" }} placeholder="998 XX XXX XX XX" />
          </Form.Item>

          <Form.Item
            label="Foydalanuvchi nomi"
            name="username"
            rules={[{ required: true, message: "Iltimos, foydalanuvchi nomingizni kiriting!" }]}
          >
            <Input placeholder="Foydalanuvchi nomini kiriting" />
          </Form.Item>

          <Form.Item
            label="Parol"
            name="password"
            rules={[{ required: true, message: "Iltimos, parolingizni kiriting!" }]}
          >
            <Input.Password placeholder="Parolni kiriting" />
          </Form.Item>

          <Form.Item
            label="Profil Rasmi"
            name="photo"
          // rules={[{ required: true, message: "Iltimos, profil rasmini yuklang!" }]}
          >
            <Upload
              maxCount={1}
              beforeUpload={() => false} // Faylni avtomatik yuklamaslik
              listType="picture-card"
              accept="image/*"
              onChange={handlePhotoUpload} // Faylni boshqarish
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Yuklash</div>
              </div>
            </Upload>
          </Form.Item>

          <Form.Item
            label="Kompaniya nomi"
            name="enterpriseName"
            rules={[{ required: true, message: "Iltimos, kompaniya nomini kiriting!" }]}
          >
            <Input placeholder="Kompaniya nomini kiriting" />
          </Form.Item>

          <Form.Item
            label="Kompaniya Logotipi"
            name="enterpriseLogo"
            rules={[{ required: true, message: "Iltimos, kompaniya logotipini yuklang!" }]}
          >
            <Upload
              maxCount={1}
              beforeUpload={() => false} // Faylni avtomatik yuklamaslik
              listType="picture-card"
              accept="image/*"
              onChange={handleEnterpriseLogoUpload} // Faylni boshqarish
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Yuklash</div>
              </div>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Ro'yxatdan o'tish
            </Button>
          </Form.Item>
          <a href="" onClick={() => navigate("/login")}>Kirish</a>
        </Form>
      </div>
    </div>
  );
};
