import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Empty,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Select,
  Spin,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { NewsEditAddModal } from "../../components/newsEditAddModal";
import Search from "antd/es/input/Search";
import { AnnouncementsEditAddModal } from "../../components/announcementsEditAddModal";
import dayjs from "dayjs";
import {
  announcmentsDelete,
  announcmentsGet,
  productCategorySelectGet,
  wareHouseDelete,
  wareHouseGet,
} from "../../api/api";
import moment from "moment";
import { IMAGE_URL } from "../../axios/constants";
import { WareHouseEditAddModal } from "../../components/wareHouseEditAddModal";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD HH:mm:ss";

export const WareHouse = () => {
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState([]);
  const [date, setDate] = useState({ from: "", to: "" });
  const [category, setcategory] = useState([]);
  const [searchWord, setsearchWord] = useState("");
  const [loading, setLoading] = useState(false);
  const [categoryId, setSetCategoryId] = useState("");
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    page: 1,
    size: 10,
    totalCount: 0,
    totalPage: 0,
  });
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };
  // edit and add modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [optionModal, setOptionModal] = useState({
    open: false,
    type: "",
    item: {},
  });

  useEffect(() => {
    setLoading(true);
    try {
      wareHouseGet(10, current,)
        .then((res) => {
          setData(res?.data?.data);
          setLoading(false);
          setPagination(res?.data?.data?.meta);
        })
        .catch((err) => console.log(err));
    } catch (er) {
      console.log(er);
    }
  }, [update, optionModal]);

  useEffect(() => {
    // setLoading(true);
    try {
      productCategorySelectGet()
        .then((res) => {
          setcategory(res.data?.data);
        })
        .catch((err) => console.log(err));
    } catch (er) {
      console.log(er);
    }
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setOptionModal({
      open: false,
    });
  };

  // delete
  const confirm = (id) => {
    try {
      wareHouseDelete(id)
        .then((res) => {
          message.success("Muvaffaqiyatli o'chirildi");
          setUpdate((prev) => !prev);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const cancel = (e) => {
    // console.log(e);
    // message.error('Click on No');
  };

  // filter date
  // const onChangeDate = (date, dateString) => {
  //   console.log("🚀 ~ onChangeDate ~ date, dateString:", date, dateString)
  //   setDate(dateString);

  // };

  const onChangeDate = (dates, dateStrings) => {
    if (dates) {
      // dates[0] - boshlang'ich sana
      // dates[1] - oxirgi sana
      // const startDate = moment(dates[0]);
      // const endDate = moment(dates[1]);
      const startDate = dateStrings[0];
      const endDate = dateStrings[1];

      // Seconds olish
      const startSeconds = startDate ? startDate : null;
      const endSeconds = endDate ? endDate : null;

      setDate({ from: startDate, to: endDate });

      console.log("Start seconds:", startSeconds);
      console.log("End seconds:", endSeconds);
      setUpdate((prev) => !prev);
    }
  };

  // on search
  const onSearch = (value) => setsearchWord(value?.target?.value);

  let { size, page, totalCount } = pagination || {};

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col d-flex justify-content-between align-items-center">
          <h3 className="mb-3">Filiallar</h3>
          <div className="d-flex align-items-center justify-content-center gap-3">
            {/* <Input
              placeholder="input search text"
              onChange={onSearch}
              allowClear
              style={{
                width: 200,
              }}
            />
            <RangePicker
              format={dateFormat}
              onChange={onChangeDate}
            />
            <Select
              defaultValue=""
              style={{
                width: 120,
              }}
              onChange={(value) => setSetCategoryId(value)}
              options={category?.map((i) => ({
                value: i?.id,
                label: i?.name,
              }))}
            /> */}
            <Button
              type="primary"
              onClick={() => {
                setOptionModal({
                  open: true,
                  type: "add",
                  item: {},
                });
              }}
            >
              + Qo'shish
            </Button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <table className="table table-bordered text-center">
            <thead>
              <tr>
                <th style={{ width: "1%" }} scope="col">
                  N
                </th>
                <th scope="col">
                  Nomi
                </th>
                <th scope="col">
                  Viloyat nomi
                </th><th scope="col">
                  Tuman nomi
                </th>
                {/* <th style={{ width: "7%" }} scope="col">
                  home Number
                </th> */}
                {/* <th scope="col">flat</th> */}
                {/* <th scope="col">region</th>
                <th scope="col">district</th> */}
                <th scope="col">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <div className="p-5 text-center">
                  <Spin />
                </div>
              ) : data?.length > 0 ? (
                data?.map((item, inx) => (
                  <tr key={item?.id}>
                    <td>
                      {current > 1 ? current * 10 - 10 + inx + 1 : inx + 1}
                    </td>
                    <td>{item?.name}</td>
                    <td>{item?.address?.regionName}</td>
                    <td>{item?.address?.districtName}</td>
                    {/* <td>

                      {item?.address?.homeNumber}
                    </td> */}
                    {/* <td>

                      {item?.address?.flat}
                    </td> */}
                    {/* <td>{item?.address?.region}</td>
                    <td>{item?.address?.district}</td> */}

                    <td>
                      <div className="d-flex justify-content-center">
                        <EditOutlined
                          onClick={() => {
                            setIsModalOpen(true);
                            setOptionModal({
                              open: true,
                              type: "edit",
                              item: item,
                            });
                          }}
                          className="fs-5 text-success"
                          style={{ cursor: "pointer" }}
                        />
                        <Popconfirm
                          title="Delete the task"
                          description="Are you sure to delete this task?"
                          onConfirm={() => confirm(item?.id)}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined
                            className="fs-5 text-danger"
                            style={{ cursor: "pointer" }}
                          />
                        </Popconfirm>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <td colSpan={5}>
                  <Empty style={{ fontSize: "25px" }} description="Ma'lumotlar topilmadi!" />
                </td>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex justify-content-center">
          <Pagination
            current={current}
            onChange={onChange}
            total={totalCount}
          />
        </div>
      </div>
      <Modal
        title="Filial qo'shish"
        open={optionModal?.open}
        onOk={handleOk}
        width={1000}
        onCancel={handleCancel}
        footer={[]}
      >
        <WareHouseEditAddModal
          category={category}
          optionModal={optionModal}
          setOptionModal={setOptionModal}
        />
      </Modal>
    </div>
  );
};
