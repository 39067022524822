import { Card, DatePicker, Empty, Pagination, Tabs, Tag } from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect, useState } from "react";
import { appealsGet } from "../../api/api";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

export const Appeals = () => {
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState([]);
  const [searchWord, setsearchWord] = useState("");
  const [type, setType] = useState("");
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    page: 1,
    size: 10,
    totalCount: 0,
    totalPage: 0,
  });
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };
  // edit and add modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [optionModal, setOptionModal] = useState({
    open: false,
    type: "",
    item: {},
  });

  useEffect(() => {
    setLoading(true);
    try {
      appealsGet(10, current, type, searchWord, date)
        .then((res) => {
          setData(res.data?.data?.items);
          setPagination(res?.data?.data?.meta);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } catch (er) {
      console.log(er);
    }
  }, [current, update, optionModal, date, searchWord, type]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setOptionModal({
      open: false,
    });
  };

  // delete
  // const confirm = (id) => {
  //   try {
  //     seedlingsDelete(id)
  //       .then((res) => {
  //         message.success("Muvaffaqiyatli o'chirildi");
  //         setUpdate((prev) => !prev);
  //         console.log(res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const cancel = (e) => {
    // console.log(e);
    // message.error('Click on No');
  };

  // filter date
  const onChangeDate = (date, dateString) => {
    console.log(dateString);
    setDate(dateString);
  };

  // on search
  const onSearch = (value) => setsearchWord(value);

  const { size, page, totalCount } = pagination;

  const onChangeTab = (key) => {
    console.log(key);
    setType(key);
  };

  const items = [
    {
      key: "",
      label: `Hamma murojaatlar`,
      children:
        data?.length > 0 ? (
          data.map((item) => (
            <div className="row">
              <div className="col-12">
                <Card
                  title={
                    <div className="d-flex justify-content-between">
                      <span>
                        <h6>
                          {item?.fio}
                          {item?.readType === "READ" ? (
                            <div></div>
                          ) : (
                            <Tag color="#108ee9">Yangi xabar</Tag>
                          )}
                        </h6>
                        Manzil: {item?.address}
                      </span>{" "}
                      <span>
                        telefon nomer:{" "}
                        <Tag color="blue">{item?.phoneNumber}</Tag>
                        <br />
                        Sana:{" "}
                        <Tag color="success">
                          {item?.createdAt?.slice(0, 10)}
                        </Tag>
                      </span>
                    </div>
                  }
                  bordered={false}
                  style={{
                    width: "100%x`",
                  }}
                  className="shadow mb-3"
                >
                  <Tag color="green">Xabar:</Tag>
                  <p>{item?.description}</p>
                </Card>
              </div>
            </div>
          ))
        ) : (
          <div className="p-5 text-center">
            <Empty />
          </div>
        ),
    },
    {
      key: "NEW",
      label: `Yangi murojaatlar`,
      children:
        data?.length > 0 ? (
          data.map((item) => (
            <div className="row">
              <div className="col-12">
                <Card
                  title={
                    <div className="d-flex justify-content-between">
                      <span>
                        <h6>
                          {item?.fio}
                          {item?.readType === "READ" ? (
                            <div></div>
                          ) : (
                            <Tag color="#108ee9">Yangi xabar</Tag>
                          )}
                        </h6>
                        Manzil: {item?.address}
                      </span>{" "}
                      <span>
                        telefon nomer:{" "}
                        <Tag color="blue">{item?.phoneNumber}</Tag>
                        <br />
                        Sana:{" "}
                        <Tag color="success">
                          {item?.createdAt?.slice(0, 10)}
                        </Tag>
                      </span>
                    </div>
                  }
                  bordered={false}
                  style={{
                    width: "100%x`",
                  }}
                  className="shadow mb-3"
                >
                  <Tag color="green">Xabar:</Tag>
                  <p>{item?.description}</p>
                </Card>
              </div>
            </div>
          ))
        ) : (
          <div className="p-5 text-center">
            <Empty />
          </div>
        ),
    },
    {
      key: "READ",
      label: `Murojaatlar`,
      children:
        data?.length > 0 ? (
          data.map((item) => (
            <div className="row">
              <div className="col-12">
                <Card
                  title={
                    <div className="d-flex justify-content-between">
                      <span>
                        <h6>
                          {item?.fio}
                          {item?.readType === "READ" ? (
                            <div></div>
                          ) : (
                            <Tag color="#108ee9">Yangi xabar</Tag>
                          )}
                        </h6>
                        Manzil: {item?.address}
                      </span>{" "}
                      <span>
                        telefon nomer:{" "}
                        <Tag color="blue">{item?.phoneNumber}</Tag>
                        <br />
                        Sana:{" "}
                        <Tag color="success">
                          {item?.createdAt?.slice(0, 10)}
                        </Tag>
                      </span>
                    </div>
                  }
                  bordered={false}
                  style={{
                    width: "100%x`",
                  }}
                  className="shadow mb-3"
                >
                  <Tag color="green">Xabar:</Tag>
                  <p>{item?.description}</p>
                </Card>
              </div>
            </div>
          ))
        ) : (
          <div className="p-5 text-center">
            <Empty />
          </div>
        ),
    },
  ];
  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col d-flex justify-content-between align-items-center">
          <h3>Direktorga murojaatlar </h3>
          <div className="d-flex align-items-center justify-content-center">
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              style={{
                width: 250,
              }}
              allowClear
            />
            <RangePicker
              format={dateFormat}
              onChange={onChangeDate}
              className="mx-2"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <Tabs defaultActiveKey="1" items={items} onChange={onChangeTab} />
      </div>
      <div className="row">
        <div className="col d-flex justify-content-center">
          <Pagination
            current={current}
            onChange={onChange}
            total={totalCount}
          />
        </div>
      </div>
    </div>
  );
};
