import React, { useEffect, useState } from "react";
import { EyeFilled } from "@ant-design/icons";
import { DatePicker, Modal, Pagination, message } from "antd";
import Search from "antd/es/input/Search";
import { useNavigate } from "react-router-dom";
import { orderList, orderServicesList } from "../../api/api";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

export const OrderForServices = () => {
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const [searchWord, setsearchWord] = useState("");
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    page: 1,
    size: 10,
    totalCount: 0,
    totalPage: 0,
  });
  const navigate = useNavigate();
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };

  useEffect(() => {
    try {
      orderServicesList(10, current, searchWord, date)
        .then((res) => {
          setData(res.data?.data?.items);
          setPagination(res?.data?.data?.meta);
        })
        .catch((err) => console.log(err));
    } catch (er) {
      console.log(er);
    }
  }, [current, date, searchWord]);

  // filter date
  const onChangeDate = (date, dateString) => {
    console.log(dateString);
    setDate(dateString)
  };

  // on search
  const onSearch = (value) => setsearchWord(value);
  const { size, page, totalCount } = pagination;

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col d-flex justify-content-between">
          <h3 className="mb-3">Buyurtmalar</h3>
          <div className="d-flex align-items-center justify-content-center">
            <Search
              placeholder="ismi bo'yich qidirish"
              onSearch={onSearch}
              style={{
                width: 200,
              }}
              allowClear
            />
            <RangePicker
              format={dateFormat}
              onChange={onChangeDate}
              className="mx-2"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th style={{ width: "7%" }} scope="col">
                  Fish
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Manzili
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Telefon raqami
                </th>
                <th style={{ width: "3%" }} scope="col">
                  Xizmatlar
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item?.id}>
                  <td>{item?.fio}</td>
                  <td>{item?.address}</td>
                  <td>{item?.phoneNumber}</td>
                  <td>
                    <ul classname="list-group list-group-flush">
                      {
                        item?.services?.map(service => (
                          <li classname="list-group-item">{service?.name}</li>
                        ))
                      }
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex justify-content-center">
          <Pagination
            current={current}
            onChange={onChange}
            total={totalCount}
          />
        </div>
      </div>
    </div>
  );
};
