import {
  FileGifOutlined,
  HomeFilled,
  LoginOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
const { Header, Sider, Content } = Layout;
const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout>
      <Sider width={250} trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical p-2" />

        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["2"]}
          items={[
            // {
            //   key: "1",
            //   icon: <HomeFilled />,
            //   label: "Dashboard",
            //   onClick: () => navigate("/"),
            // },
            // {
            //   key: "4",
            //   icon: <FileGifOutlined />,
            //   label: "Murojaatlar",
            //   onClick: () => navigate("/"),
            // },
            {
              key: "2",
              icon: <VideoCameraOutlined />,
              label: "Mahsulotlar",
              onClick: () => navigate("/"),
            },
            {
              key: "3",
              icon: <FileGifOutlined />,
              label: "Buyurtmalar",
              onClick: () => navigate("/orders"),
            },
            {
              key: "5",
              icon: <FileGifOutlined />,
              label: "Filiallar",
              onClick: () => navigate("/warehouse"),
            },
            // {
            //   key: "6",
            //   icon: <FileGifOutlined />,
            //   label: "Ko'chatlar",
            //   onClick: () => navigate("/seedlings"),
            // },

            // {
            //   key: "7",
            //   icon: <FileGifOutlined />,
            //   label: "Ko'chat buyurtmalar",
            //   onClick: () => navigate("/orderOfSeedlings"),
            // },
            // {
            //   key: "8",
            //   icon: <FileGifOutlined />,
            //   label: "Ko'chatlar turlari",
            //   onClick: () => navigate("/seedlingsTypes"),
            // },
            // {
            //   key: "9",
            //   icon: <FileGifOutlined />,
            //   label: "Yaratilgan nav va duragaylar",
            //   onClick: () => navigate("/newSpecies"),
            // },
            // {
            //   key: "10",
            //   icon: <FileGifOutlined />,
            //   label: "Xizmatlar",
            //   onClick: () => navigate("/services"),
            // },
            // {
            //   key: "11",
            //   icon: <FileGifOutlined />,
            //   label: "Xizmatlar uchun buyurtmalar",
            //   onClick: () => navigate("/orderForServices"),
            // },
          ]}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
            <h6 className="fs-6 text-center">

            </h6>
            <Button
              onClick={() => {
                localStorage.removeItem("ACCESS_TOKEN");
                navigate("/login");
              }}
              type="primary"
              className="bg-danger d-flex align-items-center me-3"
            >
              <LoginOutlined /> Chiqish
            </Button>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            maxHeight: "100vh",
            height: "100vh",
            background: colorBgContainer,
            // overflowY: "scroll",
            overflow: "hidden"
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default Dashboard;
