import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Modal,
  Pagination,
  Popconfirm,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { NewsEditAddModal } from "../../components/newsEditAddModal";
import Search from "antd/es/input/Search";
import { SeedlingsEditAddModal } from "../../components/seedlingsEditAddModal";
import dayjs from "dayjs";
import { seedlingsDelete, seedlingsGet } from "../../api/api";
import { IMAGE_URL } from "../../axios/constants";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

export const Seedlings = () => {
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const [searchWord, setsearchWord] = useState("");
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    page: 1,
    size: 10,
    totalCount: 0,
    totalPage: 0,
  });
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };
  // edit and add modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [optionModal, setOptionModal] = useState({
    open: false,
    type: "",
    item: {},
  });

  useEffect(() => {
    console.log('dadas');
    try {
      console.log('dada');
      seedlingsGet(10, current, date, searchWord, date)
        .then((res) => {
          setData(res.data?.data?.items);
          setPagination(res?.data?.data?.meta);
        })
        .catch((err) => console.log(err));
    } catch (er) {
      console.log(er);
    }
  }, [current, update, optionModal, date, searchWord]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setOptionModal({
      open: false,
    });
  };

  // delete
  const confirm = (id) => {
    try {
      seedlingsDelete(id)
        .then((res) => {
          message.success("Muvaffaqiyatli o'chirildi");
          setUpdate((prev) => !prev);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const cancel = (e) => {
    // console.log(e);
    // message.error('Click on No');
  };

  // filter date
  const onChangeDate = (date, dateString) => {
    console.log(dateString);
    setDate(dateString);
  };

  // on search
  const onSearch = (value) => setsearchWord(value);

  const { size, page, totalCount } = pagination;
  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col d-flex justify-content-between">
          <h3 className="mb-3">Ko'chatlar</h3>
          <div className="d-flex align-items-center justify-content-center">
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              allowClear
              style={{
                width: 200,
              }}
            />
            <RangePicker
              format={dateFormat}
              onChange={onChangeDate}
              className="mx-2"
            />
            <Button
              type="primary"
              onClick={() => {
                setOptionModal({
                  open: true,
                  type: "add",
                  item: {},
                });
              }}
            >
              + Qo'shish
            </Button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th style={{ width: "7%" }} scope="col">
                  Sarlavha uz
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Sarlavha ru
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Sarlavha en
                </th>
                <th scope="col">Tasnif uz</th>
                <th scope="col">Tasnif ru</th>
                <th scope="col">Tasnif en</th>
                <th style={{ width: "5%" }} scope="col">
                  Yashash vaqti
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Rasm
                </th>
                <th style={{ width: "3%" }} scope="col">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td>{item.titleUz}</td>
                  <td>{item.titleRu}</td>
                  <td>{item.titleEn}</td>
                  <td>{item.descriptionUz}</td>
                  <td>{item.descriptionRu}</td>
                  <td>{item.descriptionEn}</td>
                  <td>{item.residenceTime}</td>
                  <td>
                    <img
                      width={"100%"}
                      src={IMAGE_URL + item.photoPath}
                      alt={item.titleUz}
                    />
                  </td>
                  <td>
                    <div className="d-flex flex-column">
                      <EditOutlined
                        onClick={() => {
                          setIsModalOpen(true);
                          setOptionModal({
                            open: true,
                            type: "edit",
                            item: item,
                          });
                        }}
                        className="fs-4 text-success"
                        style={{ cursor: "pointer" }}
                      />
                      <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this task?"
                        onConfirm={() => confirm(item?.id)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteOutlined
                          className="fs-4 text-danger my-3"
                          style={{ cursor: "pointer" }}
                        />
                      </Popconfirm>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex justify-content-center">
          <Pagination
            current={current}
            onChange={onChange}
            total={totalCount}
          />
        </div>
      </div>
      <Modal
        title="Ko'chat"
        open={optionModal.open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        width={1000}
      >
        <SeedlingsEditAddModal
          optionModal={optionModal}
          setOptionModal={setOptionModal}
        />
      </Modal>
    </div>
  );
};
