import { Button, Form, Input, message } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../api/api";

export const Login = () => {
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      await login(values)
        .then((res) => {
          const token = res.data?.data?.accessToken;
          localStorage.setItem("ACCESS_TOKEN", token);
          message.success("Success")
          navigate("/");
        })
        .catch((err) => {
          message?.error(err?.response?.data?.message)
        });
    } catch (er) {
      console.log(er);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="container" style={{ height: "100vh" }}>
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="shadow px-5 w-25 pt-3">
          <h4>Qurilish express</h4>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Parol"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
            >
              <Button block type="primary" htmlType="submit">
                Kirish
              </Button>
              Sizda hali account mavjud emasmi?
              <a className="mb-3" onClick={() => navigate("/register")} type="primary">
                Ro'yxatdan o'tish
              </a>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
