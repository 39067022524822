import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Empty,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Select,
  Spin,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { NewsEditAddModal } from "../../components/newsEditAddModal";
import Search from "antd/es/input/Search";
import { AnnouncementsEditAddModal } from "../../components/announcementsEditAddModal";
import dayjs from "dayjs";
import {
  announcmentsDelete,
  announcmentsGet,
  productCategorySelectGet,
} from "../../api/api";
import moment from "moment";
import { IMAGE_URL } from "../../axios/constants";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD HH:mm:ss";

export const Product = () => {
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState([]);
  const [date, setDate] = useState({ from: "", to: "" });
  const [category, setcategory] = useState([]);
  const [searchWord, setsearchWord] = useState("");
  const [loading, setLoading] = useState(false);
  const [categoryId, setSetCategoryId] = useState("");
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    page: 1,
    size: 10,
    totalCount: 0,
    totalPage: 0,
  });
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };
  // edit and add modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [optionModal, setOptionModal] = useState({
    open: false,
    type: "",
    item: {},
  });

  useEffect(() => {
    setLoading(true);
    try {
      announcmentsGet(10, current, date, searchWord, categoryId)
        .then((res) => {
          setData(res.data?.data?.items);
          setLoading(false);
          setPagination(res?.data?.data?.meta);
        })
        .catch((err) => console.log(err));
    } catch (er) {
      console.log(er);
    }
  }, [current, update, optionModal, date, searchWord, categoryId]);

  useEffect(() => {
    // setLoading(true);
    try {
      productCategorySelectGet()
        .then((res) => {
          setcategory(res.data?.data);
        })
        .catch((err) => console.log(err));
    } catch (er) {
      console.log(er);
    }
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setOptionModal({
      open: false,
    });
  };

  // delete
  const confirm = (id) => {
    try {
      announcmentsDelete(id)
        .then((res) => {
          message.success("Muvaffaqiyatli o'chirildi");
          setUpdate((prev) => !prev);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const cancel = (e) => {
    // console.log(e);
    // message.error('Click on No');
  };

  // filter date
  // const onChangeDate = (date, dateString) => {
  //   console.log("🚀 ~ onChangeDate ~ date, dateString:", date, dateString)
  //   setDate(dateString);

  // };

  const onChangeDate = (dates, dateStrings) => {
    if (dates) {
      // dates[0] - boshlang'ich sana
      // dates[1] - oxirgi sana
      // const startDate = moment(dates[0]);
      // const endDate = moment(dates[1]);
      const startDate = dateStrings[0];
      const endDate = dateStrings[1];

      // Seconds olish
      const startSeconds = startDate ? startDate : null;
      const endSeconds = endDate ? endDate : null;

      setDate({ from: startDate, to: endDate });

      console.log("Start seconds:", startSeconds);
      console.log("End seconds:", endSeconds);
      setUpdate((prev) => !prev);
    }
  };

  // on search
  const onSearch = (value) => setsearchWord(value?.target?.value);

  let { size, page, totalCount } = pagination || {};

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col d-flex justify-content-between align-items-center">
          {/* <h3 className="mb-3">Mahsulotlar</h3> */}
          <div className="d-flex align-items-end justify-content-center gap-1">
            <div className="d-flex flex-column">
              <label htmlFor="">Qidirish</label>
              <Input
                placeholder="nomini yozing"
                onChange={onSearch}
                allowClear
                style={{
                  width: 200,
                }}
              />
            </div>
            <div className="d-flex flex-column">
              <label htmlFor="">Sanani tanlang</label>
              <RangePicker
                format={dateFormat}
                onChange={onChangeDate}
                placeholder={["qachondan", "qachongacha"]}
              // className="mx-2"
              />
            </div>
            <div className="d-flex flex-column">
              <label htmlFor="">Kategoriyasi</label>
              <Select
                defaultValue=""
                style={{
                  width: 120,
                }}
                placeholder="tanlang"
                onChange={(value) => setSetCategoryId(value)}
                options={category?.map((i) => ({
                  value: i?.id,
                  label: i?.name,
                }))}
              />
            </div>


            <Button
              type="primary"
              onClick={() => {
                setOptionModal({
                  open: true,
                  type: "add",
                  item: {},
                });
              }}
            >
              + Qo'shish
            </Button>
          </div>
        </div>
      </div>
      <div className="row" style={{ maxHeight: "calc(100vh - 112px)", overflowY: "auto" }}>
        <div className="col">
          <table className="table table-bordered text-center">
            <thead>
              <tr>
                <th style={{ width: "1%" }} scope="col">
                  N
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Nomi
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Kategoriya nomi
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Filial nomi
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Qo'shilgan vaqti
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Hozirgi narxi
                </th>
                {/* <th style={{ width: "7%" }} scope="col">
                  Rasm
                </th> */}
                <th style={{ width: "2%" }} scope="col">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <div className="p-5 text-center">
                  <Spin />
                </div>
              ) : data?.length > 0 ? (
                data?.map((item, inx) => (
                  <tr key={item?.id}>
                    <td>
                      {current > 1 ? current * 10 - 10 + inx + 1 : inx + 1}
                    </td>
                    <td>{item?.name}</td>
                    <td>{item?.categoryName}</td>
                    <td>{item?.wareHouseName}</td>
                    <td>{dayjs(item?.createdAt)?.format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td>{item?.actualPrice}</td>
                    {/* <td>
                      <div
                        style={{
                          maxHeight: "100px",
                          overflowY: "auto",
                          width: "150px",
                        }}
                      >
                        {item?.photoPaths?.map((i) => {
                          return (
                            <img key={i}
                              width={"100%"}
                              src={IMAGE_URL + i}
                              alt={item?.name}
                            />
                          )
                        })}
                      </div>
                    </td> */}
                    <td>
                      <div className="d-flex gap-2 justify-content-center">
                        <EditOutlined
                          onClick={() => {
                            setIsModalOpen(true);
                            setOptionModal({
                              open: true,
                              type: "edit",
                              item: item,
                            });
                          }}
                          className="fs-5 text-success"
                          style={{ cursor: "pointer" }}
                        />
                        <Popconfirm
                          title="Delete the task"
                          description="Are you sure to delete this task?"
                          onConfirm={() => confirm(item?.id)}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined
                            className="fs-5 text-danger"
                            style={{ cursor: "pointer" }}
                          />
                        </Popconfirm>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <td colSpan={7}>
                  <Empty style={{ fontSize: "25px" }} description="Ma'lumotlar topilmadi!" />
                </td>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex justify-content-center">
          <Pagination
            current={current}
            onChange={onChange}
            total={totalCount}
          />
        </div>
      </div>
      <Modal
        title="Mahsulot qo'shish"
        open={optionModal?.open}
        onOk={handleOk}
        width={1000}
        onCancel={handleCancel}
        footer={[]}
      >
        <AnnouncementsEditAddModal
          category={category}
          optionModal={optionModal}
          setOptionModal={setOptionModal}
        />
      </Modal>
    </div>
  );
};
