import axios from "axios";
import { BASE_URL } from "./constants";

let instance = axios.create({});

instance.defaults.baseURL = BASE_URL;

const onRequestSuccess = (config) => {
  let token = localStorage.getItem("ACCESS_TOKEN");
  // config.headers["ACCEPT-LANGUAGE"] = "uz";
  if (token) {
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
};

const onRequestError = (config) => {
  return Promise.reject(config);
};

const onResponseSuccess = (config) => {
  return config;
};

const onResponseError = (config) => {
  return Promise.reject(config);
};

instance.interceptors.request.use(onRequestSuccess, onRequestError);

instance.interceptors.response.use(onResponseSuccess, onResponseError);

export default instance;
